import { getLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";

export const documentsInfo = {
  UHF: {
    documents: [
      {
        description: "Welcome Booklet",
        disable: "disableUHFWelcome",
        function: "getBookletLink",
        link: "https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/BIG/UHS-Welcome-Booklet.pdf",
      },
      {
        description: "Sharing Guidelines",
        disable: "disableUHFSharing",
        link: "https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHS-Sharing-Program-Guidelines.pdf",
        function: "opensharingguidlines",
      },
      {
        description: "Member Responsibilities",
        link: "https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHS+Member+Responsibilities.pdf",
      },
      {
        description: "Statement of Shared Faith and Beliefs",
        link: "https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHF-Statement-of-Shared-Faith-%26-Beliefs.pdf",
      },
      {
        description: "Get Started with Health Sharing",
        link: "https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+How+do+I+Get+Started+with+Health+Sharing.pdf",
      },
      {
        description: "5 Questions about Health Sharing",
        link: "https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+5+Questions+about+Health+Sharing.pdf",
      },
      {
        description: "Tips on Telemedicine",
        link: "https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+Tips+on+Telemedicine.pdf",
        show: () =>
          getLocalStorage("NETWORK_NAME") !== "THS" &&
          !["10051", "10052", "10053"].includes(
            getLocalStorage("planId") || ""
          ),
      },
      {
        description: "4 Great Reasons to share UHS",
        link: "https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+4+Great+Reasons+to+share+Universal+HealthShare+with+Your+Friends.pdf",
      },
      {
        description: "Pharmacy Vaccine Reimbursement Transmittal",
        link: "https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHF-Pharmacy-Vaccine-Reimbursement-Transmittal.pdf",
      },
    ],
    "not-found": `We’re facing some technical difficulties, due to which this feature is currently unavailable. For support, call Member
        Services at ${localStorage.getItem(
          "CONTACT_NUMBER"
        )}, Monday through Friday, 8.00am to 8.00pm CST.`,
  },
  NETWELL: {
    documents: [
      {
        id: [
          "1004",
          "2004",
          "2005",
          "2006",
          "2007",
          "1005",
          "2013",
          "1007",
          "1006",
          "1008",
          "1009",
          "2021",
          "1010",
          "2018",
        ],
        list: [
          {
            description: "netWell Comprehensive Book",
            link: "https://netwell-prod.s3.amazonaws.com/Member/netWell-Comprehensive-Book.pdf",
          },
          {
            description: "Elite+ Program Grid",
            link: "https://netwell-prod.s3.amazonaws.com/Agent/Elite%2B-Program-Grid.pdf",
          },
          {
            description: "Elite+ Brochure",
            link: "https://netwell-prod.s3.amazonaws.com/Agent/Elite%2B-Brochure.pdf",
          },
          {
            description: "netWellRX Brochure",
            link: "https://netwell-prod.s3.amazonaws.com/Agent/netWellRX-Brochure.pdf",
          },
        ],
      },
      {
        id: ["2020"],
        list: [
          {
            description: "PURE Catastrophic Member Guide",
            link: "https://netwell-prod.s3.amazonaws.com/Agent/PURE-Catastrophic-Member-Guide.pdf",
          },
          {
            description: "PURE Catastrophic Program Grid",
            link: "https://netwell-prod.s3.amazonaws.com/Agent/PURE-Catastrophic-Program-Grid.pdf",
          },
          {
            description: "PURE Catastrophic Brochure",
            link: "https://netwell-prod.s3.amazonaws.com/Agent/PURE-Catastrophic-Brochure.pdf",
          },
        ],
      },
      {
        id: ["1002", "2003", "2012", "2014", "2015", "2016", "2017", "2019"],
        list: [
          {
            description: "WellLife+ Member Guide",
            link: "https://netwell-prod.s3.amazonaws.com/Agent/WellLife%2B-Member-Guide.pdf",
          },
          {
            description: "WellLife+ Program Grid",
            link: "https://netwell-prod.s3.amazonaws.com/Agent/WellLife%2B-Program-Grid.pdf",
          },
          {
            description: "WellLife+ Brochure",
            link: "https://netwell-prod.s3.amazonaws.com/Agent/WellLife%2B-Brochure.pdf",
          },
          {
            description: "netWellRX Brochure",
            link: "https://netwell-prod.s3.amazonaws.com/Agent/netWellRX-Brochure.pdf",
          },
        ],
      },
      {
        id: [
          "1004",
          "2004",
          "2005",
          "2006",
          "2007",
          "2013",
          "1007",
          "1006",
          "1008",
          "1009",
          "2021",
          "1010",
          "2018",
        ],
        list: [
          {
            description: "netWell Comprehensive Book",
            link: "https://netwell-prod.s3.amazonaws.com/Member/netWell-Comprehensive-Book.pdf",
          },
          {
            description: "Elite+ Brochure",
            link: "https://netwell-prod.s3.amazonaws.com/Agent/Elite%2B-Brochure.pdf",
          },
          {
            description: "Elite+ Program Grid",
            link: "https://netwell-prod.s3.amazonaws.com/Agent/Elite%2B-Program-Grid.pdf",
          },
          {
            description: "WellLife+ Member Guide",
            link: "https://netwell-prod.s3.amazonaws.com/Agent/WellLife%2B-Member-Guide.pdf",
          },
          {
            description: "WellLife+ Brochure",
            link: "https://netwell-prod.s3.amazonaws.com/Agent/WellLife%2B-Brochure.pdf",
          },
          {
            description: "WellLife+ Program Grid",
            link: "https://netwell-prod.s3.amazonaws.com/Agent/WellLife%2B-Program-Grid.pdf",
          },
          {
            description: "netWellRX Brochure",
            link: "https://netwell-prod.s3.amazonaws.com/Agent/netWellRX-Brochure.pdf",
          },
        ],
      },
    ],
    "not-found":
      "We are currently updating this feature and will be up shortly. Stay tuned!",
  },
  CURAMLIFE: {
    documents: [
      {
        description: "HowToRegisterForFlexCare",
        document:
          "https://curamlife-prod.s3.us-east-2.amazonaws.com/English/How-to-Register-for-Your-CuramLife-Program-Health-Apps.pdf",
        SPDocument:
          "https://curamlife-prod.s3.us-east-2.amazonaws.com/Spanish/How-to-Register-for-Your-CuramLife-Program-Health-Apps.pdf",
        activeFlag: true,
      },
      {
        description: "HowToRegisterForNutrition",
        document:
          "https://curamlife-prod.s3.us-east-2.amazonaws.com/English/How-to-Register-for-Nutrition-%26-Dietician-Counseling.pdf",
        SPDocument:
          "https://curamlife-prod.s3.us-east-2.amazonaws.com/Spanish/How-to-Register-for-Nutrition-%26-Dietician-Counseling.pdf",
        planlist: ["Curam 3", "Curam 4"],
        addon: ["Nutrition/Dietician Counseling"],
        activeFlag: true,
      },
      {
        description:"HospitalFinancialAssistantApplicationSupport",
        document:
          "https://curamlife-prod.s3.us-east-2.amazonaws.com/English/Hospital-Financial-Assistance-Application-Support.pdf",
        SPDocument:
          "https://curamlife-prod.s3.us-east-2.amazonaws.com/Spanish/Hospital-Financial-Assistance-Application-Support.pdf",
        planlist: ["Curam 1","Curam 2","Curam 3", "Curam 4"],
        activeFlag: true,
      },
      {
        description:"VirtualOnDemandCare",
        document:
          "https://curamlife-prod.s3.us-east-2.amazonaws.com/English/Virtual-On-Demand-Care.pdf",
        SPDocument:
          "https://curamlife-prod.s3.us-east-2.amazonaws.com/Spanish/Virtual-On-Demand-Care.pdf",
        planlist: ["Curam 1","Curam 2","Curam 3", "Curam 4"],
        activeFlag: true,
      },
      {
        description: "BehavioralHealthFlyer",
        document:
          "https://curamlife-prod.s3.us-east-2.amazonaws.com/English/Virtual-Behavioral-Health.pdf",
        SPDocument:
          "https://curamlife-prod.s3.us-east-2.amazonaws.com/Spanish/Virtual-Behavioral-Health.pdf",
        planlist: ["Curam 2", "Curam 3", "Curam 4"],
        activeFlag: true,
      },
      {
        description: "VirtualPrimaryCareFlyer",
        document:
          "https://curamlife-prod.s3.us-east-2.amazonaws.com/English/Virtual-Primary-Care.pdf",
        SPDocument:
          "https://curamlife-prod.s3.us-east-2.amazonaws.com/Spanish/Virtual-Primary-Care.pdf",
        planlist: ["Curam 2", "Curam 3", "Curam 4"],
        activeFlag: true,
      },
      {
        description: "CuramConcierge",
        document:
          "https://curamlife-prod.s3.us-east-2.amazonaws.com/English/Curam-Concierge.pdf",
        SPDocument:
          "https://curamlife-prod.s3.us-east-2.amazonaws.com/Spanish/Curam-Concierge.pdf",
        planlist: ["Curam 4"],
        addon: ["Curam Concierge"],
        activeFlag: true,
      },
      {
        description: "LoworNoCostPrescriptions",
        document:
          "https://curamlife-prod.s3.us-east-2.amazonaws.com/English/Low-or-No-Cost-Prescriptions.pdf",
        SPDocument:
          "https://curamlife-prod.s3.us-east-2.amazonaws.com/Spanish/Low-or-No-Cost-Prescriptions.pdf",
        planlist: ["Curam 3", "Curam 4"],
        addon: ["Low or No Cost Prescriptions"],
        activeFlag: true,
      },
      {
        description: "CuramLifeCareCoordinationApp",
        document:
          "https://curamlife-prod.s3.us-east-2.amazonaws.com/English/CuramLife-Care-Coordination-App.pdf",
        SPDocument:
          "https://curamlife-prod.s3.us-east-2.amazonaws.com/Spanish/CuramLife-Care-Coordination-App.pdf",
        planlist: ["Curam 4"],
        addon: ["CuramLife Care Coordination App"],
        activeFlag: true,
      },
      {
        description: "VirtualPetCare",
        document:
          "https://curamlife-prod.s3.us-east-2.amazonaws.com/English/Virtual-Pet-Care.pdf",
        SPDocument:
          "https://curamlife-prod.s3.us-east-2.amazonaws.com/Spanish/Virtual-Pet-Care.pdf",
        addon: ["Virtual Pet Care"],
        activeFlag: true,
      },
      {
        description: "VirtualDermatology",
        document:
          "https://curamlife-prod.s3.us-east-2.amazonaws.com/English/Virtual-Dermatology.pdf",
        SPDocument:
          "https://curamlife-prod.s3.us-east-2.amazonaws.com/Spanish/Virtual-Dermatology.pdf",
        addon: ["Virtual Dermatology"],
        activeFlag: true,
      },
      {
        description: "CompanionCare",
        document:
          "https://curamlife-prod.s3.us-east-2.amazonaws.com/English/Companion-Care.pdf",
        SPDocument:
          "https://curamlife-prod.s3.us-east-2.amazonaws.com/Spanish/Companion-Care.pdf",
        addon: ["Companion Care"],
        activeFlag: true,
      },
      {
        description: "VirtualNeckandBack",
        document:
          "https://curamlife-prod.s3.us-east-2.amazonaws.com/English/Virtual-Neck-and-Back.pdf",
        SPDocument:
          "https://curamlife-prod.s3.us-east-2.amazonaws.com/Spanish/Virtual-Neck-and-Back.pdf",
        addon: ["Virtual Neck and Back"],
        activeFlag: true,
      },
      {
        description: "VirtualDentistry",
        document:
          "https://curamlife-prod.s3.us-east-2.amazonaws.com/English/Virtual-Dentistry.pdf",
        SPDocument:
          "https://curamlife-prod.s3.us-east-2.amazonaws.com/Spanish/Virtual-Dentistry.pdf",
        addon: ["Virtual Dentistry"],
        activeFlag: true,
      },
      {
        description: "DiscountVisionandDiscountDental",
        document:
          "https://curamlife-prod.s3.us-east-2.amazonaws.com/English/Discount-Vision-and-Discount-Dental.pdf",
        SPDocument:
          "https://curamlife-prod.s3.us-east-2.amazonaws.com/Spanish/Discount-Vision-and-Discount-Dental.pdf",
        addon: ["Discount Vision and Discount Dental"],
        activeFlag: true,
      },
      {
        description: "DiscountImaging",
        document:
          "https://curamlife-prod.s3.us-east-2.amazonaws.com/CuramLife-GI-Low-Cost-Imaging.pdf",
        SPDocument:
          "https://curamlife-prod.s3.us-east-2.amazonaws.com/CuramLife-GI-Low-Cost-Imaging.pdf",
        activeFlag: true,
      },
    ],
    "not-found":
      "We are currently updating this feature and will be up shortly. Stay tuned!",
  },
};
